<template>
  <v-form
    id="pop-up"
    @submit.prevent="onSave"
    class="pa-3"
  >
    <v-app-bar
      class="white pa-0 ma-0"
      elevate-on-scroll
      flat
      height="60"
      style="z-index: 3;"
    >
      <v-toolbar-title
        class="text-h5 ml-4 font-weight-bold"
        data-cy="screen_batchdetail_dialog_title"
      >
        {{ $t('screen.batchdetail.title') }}
      </v-toolbar-title>
      <v-btn
        class="ml-5"
        color="primary"
        :href="download_url"
        target="_blank"
        data-cy="batchdetail_download_btn"
      >
        <v-icon
          left
          medium
        >
          mdi-download
        </v-icon>
        {{ $t('screen.batchdetail.buttons.save') }}
      </v-btn>
      <v-spacer />
      <v-btn
        @click="closeDialog"
        fab
        color="transparent"
        elevation="0"
        class="ma-2"
        data-cy="batchdetail_close_btn"
      >
        <v-icon medium>
          mdi-close
        </v-icon>
      </v-btn>
    </v-app-bar>
    <v-row
      no-gutters
      class="pa-2"
    >
      <v-col cols="6">
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.reference_id.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <span>{{ record.id }}</span>
          </v-col>
        </v-row>
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.status.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <div v-if="record.is_completed">
              <v-icon
                style="color: #36F27B"
                class="mb-2"
              >
                mdi-check-circle
              </v-icon>
              <!--<v-icon size="20">$icCompleted</v-icon>-->
              <span class="ml-2">{{ $t('screen.batchdetail.details.status.completed') }}</span>
            </div>
            <div v-else-if="record.is_failed">
              <v-icon
                style="color: #F44336"
                class="mb-2"
              >
                mdi-alert-circle
              </v-icon>
              <!--<v-icon size="20">$icFailed</v-icon>-->
              <span class="ml-2">{{ $t('screen.batchdetail.details.status.failed') }}</span>
            </div>
            <div v-else-if="record.is_paused">
              <v-icon
                style="color: #FFB129"
                class="mb-2"
              >
                mdi-stop-circle
              </v-icon>
              <!--<v-icon size="20">$icPaused</v-icon>-->
              <span class="ml-2">{{ $t('screen.batchdetail.details.status.paused') }}</span>
            </div>
            <div v-else>
              <span class="ml-2">{{ $t('screen.batchdetail.details.status.unknown') }}</span>
            </div>
          </v-col>
        </v-row>
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.duration.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <span>
              {{ durationInString }}
              <!--{{moment.duration(moment(record.updated_date).diff(moment(record.create_date))).locale($i18n.locale == 'zh-Hant' ? 'zh-tw' : 'en').humanize()}}-->
            </span>
          </v-col>
        </v-row>
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.start_date.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <span>{{ moment(this.record.create_date).format('DD-MM-YYYY hh:mm:ss') }}</span>
          </v-col>
        </v-row>
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.end_date.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <span>{{ moment(this.record.updated_date).format('DD-MM-YYYY hh:mm:ss') }}</span>
          </v-col>
        </v-row>
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.processed_record.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <span>{{ record.total_processed_company + record.total_processed_person }}</span>
          </v-col>
        </v-row>
        <v-row class="py-2 ma-0 text-row">
          <v-col
            cols="6"
            class="textLabel"
          >
            <span>{{ $t('screen.batchdetail.details.initiator.label') }} :</span>
          </v-col>
          <v-col cols="6">
            <span>{{ record.initiator }}</span>
          </v-col>
        </v-row>

        <!-- Bottom Card Section -->
        <v-row class="mt-5">
          <!-- Card: Alert Generated Primary color: red--text #F44336 SubColor: red--text text--lighten-3 #EF9A9A -->
          <v-col
            cols="6"
            class="pl-5"
          >
            <v-card
              class="pb-3 basics alert"
              elevation="2"
            >
              <v-list-item>
                <v-list-item-content :class="[totalAlert>1000 || totalAlert<-1000 ? 'text-h4' : 'text-h2','red--text']">
                  {{ totalAlert }}
                </v-list-item-content>
                <v-list-item-icon class="ma-0">
                  <v-icon
                    size="80"
                    color="#EF9A9A"
                  >
                    mdi-alert
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item style="min-height: 20px">
                <v-list-item-subtitle class=" mb-0 red--text">
                  {{ $t('screen.batchdetail.details.alert_generated.label') }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-card>
          </v-col>
          <!-- Card: Duration Primary color: grey--text text--darken-3 #424242 SubColor: grey--text text--lighten-1 #BDBDBD -->
          <v-col
            cols="6"
            class="pl-0"
          >
            <v-card
              class="pb-3 basics duration"
              elevation="2"
            >
              <v-list-item>
                <v-list-item-content :class="[duration>1000 || duration<-1000 ? 'text-h4' : 'text-h2','grey--text text--darken-3']">
                  {{ duration }}
                  <!--{{moment.duration(moment(record.updated_date).diff(moment(record.create_date))).humanize()}}  -->
                </v-list-item-content>
                <v-list-item-icon class="ma-0">
                  <v-icon
                    size="80"
                    color="#BDBDBD"
                  >
                    mdi-clock-time-four-outline
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item style="min-height: 20px">
                <v-list-item-subtitle class="mb-0 grey--text text--lighten-1">
                  {{ $t('screen.batchdetail.details.duration.label') }}
                  <!--({{$t('screen.batchdetail.details.duration.label_unit.minute')}})-->
                  ({{ label_unit }})
                </v-list-item-subtitle>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <!-- Card: Natural Person Primary color: blue--text text--accent-4 #2962FF SubColor: blue--text text--accent-1 #82B1FF -->
          <v-col
            cols="6"
            class="pl-5"
          >
            <v-card
              class="pb-3 basics person"
              outlined
              elevation="2"
            >
              <v-list-item>
                <v-list-item-content :class="[record.total_processed_person>1000 || record.total_processed_person<-1000 ? 'text-h4' : 'text-h2','blue--text text--accent-4']">
                  {{ record.total_processed_person }}
                </v-list-item-content>
                <v-list-item-icon class="ma-0">
                  <v-icon
                    size="80"
                    color="blue accent-1"
                  >
                    mdi-account
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item style="min-height: 20px">
                <v-list-item-subtitle class="mb-0 blue--text text--accent-1">
                  {{ $t('screen.batchdetail.details.person.label') }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-card>
          </v-col>
          <!-- Card: Company Primary color: deep-purple--text text--lighten-1 #7E57C2 SubColor: deep-purple--text text--accent-1 #B388FF -->
          <v-col
            cols="6"
            class="pl-0"
          >
            <v-card
              class="pb-3 basics companies"
              outlined
              elevation="2"
            >
              <v-list-item>
                <v-list-item-content :class="[record.total_processed_company>1000 || record.total_processed_company<-1000 ? 'text-h4' : 'text-h2','deep-purple--text text--lighten-1']">
                  {{ record.total_processed_company }}
                </v-list-item-content>
                <v-list-item-icon class="ma-0">
                  <v-icon
                    size="80"
                    color="#B388FF"
                  >
                    mdi-bank
                  </v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item style="min-height: 20px">
                <v-list-item-subtitle class="mb-0 deep-purple--text text--accent-1">
                  {{ $t('screen.batchdetail.details.companies.label') }}
                </v-list-item-subtitle>
              </v-list-item>
            </v-card>
          </v-col>
        </v-row>
      </v-col>

      <!-- table section -->
      <v-col
        cols="6"
        class="pa-3"
      >
        <v-card
          id="screen_list"
          elevation="2"
        >
          <template>
            <v-data-table
              :loading="requestLoading"
              :headers="table.headers"
              :items="table.items"
              :options.sync="table.options"
              :server-items-length="this.record ? this.record.total_processed_company + this.record.total_processed_person : 0"
              item-key="id"
              class="elevation-1"
              :footer-props="{
                itemsPerPageOptions: [10]
              }"
            >
              <template v-slot:[`item.sys_id`]="{ item }">
                <v-icon
                  v-if="item.type==='naturalperson'"
                  color="#82B1FF"
                >
                  mdi-account
                </v-icon>
                <v-icon
                  v-else
                  color="#BA91F5"
                >
                  mdi-bank
                </v-icon>
                {{ item.sys_id }}
              </template>
              <template v-slot:[`item.alert`]="{ item }">
                <span v-if="item.alert && item.alert.length===0">-</span>
                <span v-else>{{ item.alert.toString() }}</span>
              </template>
            </v-data-table>
          </template>
        </v-card>
      </v-col>
    </v-row>
    <!-- </v-container> -->
  </v-form>
  <!-- </v-container> -->
</template>

<script type="text/javascript">

import moment from 'moment'
import { mapActions } from 'vuex'

export default {

  props: {
    record: {
      type: Object,
      default: null
    }
  },

  computed: {
    totalAlert () {
      let totalAlert = 0
      this.logs.forEach((element) => {
        if (element.generated_alert instanceof Array) {
          totalAlert += element.generated_alert.length
        } else if (typeof element.generated_alert === 'number' && element.generated_alert > 0) {
          // for some log, it would be alert id instead of array of alert ids
          totalAlert += 1
        }
      })
      return totalAlert
    },
    moment () {
      return moment
    },

    // duration in one unit
    duration () {
      let temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asDays()
      temp = temp > 1 ? Math.round(temp) : 0
      // console.log("day"+temp)

      if (temp !== 0) {
        // this.label_unit = this.$t('screen.batchdetail.details.duration.label_unit.day')
        return temp
      }

      temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asHours()
      temp = temp > 1 ? Math.round(temp) : 0
      // console.log("Hours"+temp)
      if (temp !== 0) {
        // this.label_unit = this.$t('screen.batchdetail.details.duration.label_unit.hour')
        return temp
      }
      temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asMinutes()
      temp = temp > 1 ? Math.round(temp) : 0
      // console.log("minutes"+temp)
      if (temp !== 0) {
        // this.label_unit = this.$t('screen.batchdetail.details.duration.label_unit.minute')
        return temp
      }

      temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asSeconds()
      temp = temp > 1 ? Math.round(temp) : 0
      // this.label_unit = this.$t('screen.batchdetail.details.duration.label_unit.second')
      return temp
    },

    // label unit
    label_unit () {
      let temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asDays()
      temp = temp > 1 ? Math.round(temp) : 0
      let label_unit = ''
      // console.log("day"+temp)

      if (temp !== 0) {
        label_unit = this.$t('screen.batchdetail.details.duration.label_unit.day')
        return label_unit
      }

      temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asHours()
      temp = temp > 1 ? Math.round(temp) : 0
      // console.log("Hours"+temp)
      if (temp !== 0) {
        label_unit = this.$t('screen.batchdetail.details.duration.label_unit.hour')
        return label_unit
      }
      temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asMinutes()
      temp = temp > 1 ? Math.round(temp) : 0
      // console.log("minutes"+temp)
      if (temp !== 0) {
        label_unit = this.$t('screen.batchdetail.details.duration.label_unit.minute')
        return label_unit
      }

      temp = this.moment.duration(moment(this.record.updated_date).diff(moment(this.record.create_date))).asSeconds()
      temp = temp > 1 ? Math.round(temp) : 0
      label_unit = this.$t('screen.batchdetail.details.duration.label_unit.second')
      return label_unit
    },

    // calculate the duration in days, hours, mins and secs
    durationInString () {
      let duration = moment(this.record.updated_date).diff(moment(this.record.create_date), 'miliseconds')// duration in sec
      duration = Math.round(duration / 1000)
      var duration_string = ''
      // duration = 0
      if (duration === 0) {
        duration_string += 0 + ' ' + this.$t('screen.batchdetail.details.duration.unit.sec')
        return duration_string
      }
      // duration >0
      const sec = duration % 60
      duration = (duration - sec) / 60
      const min = duration % 60
      duration = (duration - min) / 60

      const hour = duration % 24
      const days = (duration - hour) / 24
      // assign unit to duration string
      duration_string += (days === 0) ? '' : days + ' ' + (this.$t('screen.batchdetail.details.duration.unit.day') +
                                        ((days === 1) ? '' : this.$t('screen.batchdetail.details.duration.connection_word.plural')))
      if (duration_string.includes('day') && (hour !== 0 || min !== 0 || sec !== 0)) {
        duration_string += this.$t('screen.batchdetail.details.duration.connection_word.label')
      }

      duration_string += (hour === 0) ? '' : hour + ' ' + (this.$t('screen.batchdetail.details.duration.unit.hour') +
                                            ((hour === 1) ? '' : this.$t('screen.batchdetail.details.duration.connection_word.plural')))

      if (duration_string.includes('and') && duration_string.includes('hour')) {
        return duration_string
      }
      if ((duration_string.includes('hour') || duration_string.includes('day')) && (min !== 0 || sec !== 0)) {
        duration_string += this.$t('screen.batchdetail.details.duration.connection_word.label')
      }

      duration_string += (min === 0) ? '' : min + ' ' + (this.$t('screen.batchdetail.details.duration.unit.min') +
                                        ((min === 1) ? '' : this.$t('screen.batchdetail.details.duration.connection_word.plural')))

      if (duration_string.includes('and') && duration_string.includes('minute')) {
        return duration_string
      }

      if ((duration_string.includes('minute') || duration_string.includes('hour') || duration_string.includes('day')) && (sec !== 0)) {
        duration_string += this.$t('screen.batchdetail.details.duration.connection_word.label')
      }

      duration_string += (sec === 0) ? '' : sec + ' ' + (this.$t('screen.batchdetail.details.duration.unit.sec') +
                                            ((sec === 1) ? '' : this.$t('screen.batchdetail.details.duration.connection_word.plural')))

      return duration_string
    },

    alertIds (alerts) {
      let temp = ''
      let i = 0
      for (i = 0; i < this.alerts.length; i++) {
        if (i === 0) {
          temp += alerts[i].toString()
        } else if (i === alerts.length - 1) {
          temp += alerts[i].toString()
        } else {
          temp += alerts[i].toString() + ','
        }
      }
      return temp
    }
  },

  data () {
    return {
      totalAlerts: 0,
      requestLoading: false,
      table: {
        options: {
          sortBy: 'name',
          sortDesc: false
        },
        items: [],
        headers: [
          { text: this.$t('screen.batchdetail.table.header.col_1'), value: 'sys_id', width: '25%', sortable: false, class: 'grey lighten-3' },
          { text: this.$t('screen.batchdetail.table.header.col_2'), value: 'name', width: '55%', sortable: false, class: 'grey lighten-3' },
          { text: this.$t('screen.batchdetail.table.header.col_3'), value: 'alert', width: '20%', sortable: false, class: 'grey lighten-3' }
        ]
      },
      logs: [],
      download_url: '#'
    }
  },

  methods: {
    ...mapActions('scan', [
      'get_report_download_url'
    ]),
    closeDialog () {
      this.$emit('closeDialog', false)
    },

    generateTableItem () {
      const { page, itemsPerPage } = this.table.options
      if (this.requestLoading) return
      // this.table.items= []
      const company_ids = []
      const person_ids = []
      this.requestLoading = true
      // Promise.all([
      new Promise((resolve, reject) => {
        if (!this.logs.length) {
          this.$client.system_batch_history.log.findWhere(this.record.id, {
            where: {
              status: {
                $In: ['completed']
              }
            },
            limit: this.record ? this.record.total_company_ids + this.record.total_person_ids : 0
          })
            .then((results) => {
              this.logs.splice(0)
              this.logs = results.data.data
              resolve(this.logs.slice((page - 1) * itemsPerPage, itemsPerPage * page))
            })
            .catch(reject)
        } else {
          resolve(this.logs.slice((page - 1) * itemsPerPage, itemsPerPage * page))
        }
      })
        .then((results) => {
          return [
            { data: { data: results.filter((record) => record.type === 'company') } },
            { data: { data: results.filter((record) => record.type === 'naturalperson') } }
          ]
        })
        .then(async ([company_data, person_data]) => {
          // console.log(company_data, person_data);
          company_data.data.data.forEach((element) => {
            company_ids.push(element.sys_id)
          })
          person_data.data.data.forEach((element) => {
            person_ids.push(element.sys_id)
          })
          await Promise.all([
            this.$client.name.findWhere({
              where: {
                company: company_ids
              },
              limit: 1500
            }),
            this.$client.name.findWhere({
              where: {
                person: person_ids
              },
              limit: 1500
            }),
            this.$client.identification.findWhere({
              where: {
                parent: { $In: person_ids }
              }
            })
          ])
            .then(([name_result, name_result2, identificationResults]) => {
              this.table.items = []
              if (name_result.data.length > 0) {
                this.setName(name_result, company_data)
              }
              if (name_result2.data.length > 0) {
                this.setName(name_result2, person_data, identificationResults.data)
              }
            })
            .catch((e) => {
              console.log(e)
            })
        }).catch((e) => {
          console.log(e)
        }).finally(() => {
          // console.log(this.table.items)
          this.requestLoading = false
        })
    },

    async setName (name_result, results, identificationResults) {
      const uniqueArray = []
      let item = {}
      name_result.data[0].company != null ? name_result.data.map(x => uniqueArray.filter(a => a.company.id === x.company.id).length > 0 ? null : uniqueArray.push(x))
        : name_result.data.map(x => uniqueArray.filter(a => a.person.id === x.person.id).length > 0 ? null : uniqueArray.push(x))
      let j = uniqueArray.length - 1

      while (results.data.data.length > 0) {
        const logData = results.data.data[0]
        if (uniqueArray.length === 0) {
          item.type = logData.type
          item.name = '-'
          item.alert =
            logData.generated_alert && logData.generated_alert.length
              ? logData.generated_alert
              : (
                logData.entities && logData.entities.length
                  ? logData.entities
                  : []
              )
          item.sys_id = ''
          results.data.data.splice(0, 1)
          this.table.items.push(item)
          item = {}
        } else if (logData.sys_id === (name_result.data[0].company != null ? uniqueArray[j].company.id : uniqueArray[j].person.id)) {
          item.type = logData.type
          item.name = name_result.data[0].company != null ? uniqueArray[j].other : uniqueArray[j].sur + ' ' + uniqueArray[j].other
          item.alert =
            logData.generated_alert && logData.generated_alert.length
              ? logData.generated_alert
              : (
                logData.entities && logData.entities.length
                  ? logData.entities
                  : []
              )
          // item.sys_id = uniqueArray[j].id?uniqueArray[j].id:''
          if (name_result.data[0].person != null) {
            // let counter =0;

            // await this.$axios.get(`v2/individuals/${uniqueArray[j].person.id}/identifications`)
            // .then((results) => {
            (identificationResults || []).filter(identification => identification.parent.id === uniqueArray[j].person.id)
              .map((results) => {
                if (results) {
                  item.sys_id = results.id_number
                // } else if (results.length > 1) {
                //   const temp = results.reduce((a, b) => (a.updated_date > b.updated_date ? a : b))
                //   item.sys_id = temp.id_number
                } else {
                  item.sys_id = '-'
                }
              })
          } else {
            item.sys_id = uniqueArray[j].company.coi_number !== '' ? uniqueArray[j].company.coi_number : '-'
          }
          results.data.data.splice(0, 1)
          uniqueArray.splice(j, 1)
          this.table.items.push(item)
          item = {}
          j = uniqueArray.length - 1
        } else {
          j--
        }
        if (j === -1 && results.data.data.length > 0) {
          item.type = logData.type
          item.name = '-'
          item.alert =
            logData.generated_alert && logData.generated_alert.length
              ? logData.generated_alert
              : (
                logData.entities && logData.entities.length
                  ? logData.entities
                  : []
              )
          item.sys_id = ''
          results.data.data.splice(0, 1)
          this.table.items.push(item)
          item = {}
          j = uniqueArray.length - 1
        }
      }
      this.table.items.sort((a, b) => a.name.localeCompare(b.name))
    }
  },

  watch: {
    async record (_new_record) {
      this.logs.splice(0)
      this.table.items.splice(0)
      this.table.options.page = 1
      this.generateTableItem()
      this.get_report_download_url({ batch_id: _new_record.id }).then((download_url) => {
        this.download_url = download_url
      }).catch((unusedError) => {
        this.download_url = '#'
      })
    },
    'table.options': {
      deep: true,
      handler () {
        this.generateTableItem()
      }
    }
  },

  mounted () {
    this.generateTableItem()
    this.get_report_download_url({ batch_id: this.record.id }).then((download_url) => {
      this.download_url = download_url
    }).catch((unusedError) => {
      this.download_url = '#'
    })
  }
}
</script>

<style type="text/css" scoped>
.text-row {
    max-height: 35px;
}

.textLabel {
    font-weight: 700;
    font-size: 15px;
}

.v-list-item .v-list-item__subtitle,.v-list-item__icon {
    opacity: 0.55;
}

.v-list-item__subtitle {
    font-size: 20px;
    font-weight: 900;
}

.v-card.basics {
    max-width: 250px;
    min-width: 180px;
}

.v-card.alert {
    border-bottom: 5px solid #F44336;
}

.v-card.duration {
    border-bottom: 5px solid #424242;
}

.v-card.person {
    border-bottom: 5px solid #2962FF ;
}

.v-card.companies {
    border-bottom: 5px solid #7E57C2;
}

.v-list-item .v-list-item__content {
    margin-bottom: 0px;
    padding: 0px;
    font-weight: 900;
    font-size: 3rem;
    align-self: flex-end;
}

#screen_list {
    border-radius: 5px;
}

</style>
