<template>
  <v-card
    class="mx-auto"
    width="100%"
  >
    <v-container
      fill-height
      fluid
    >
      <div slot="afterFilterWrapper">
        <div>
          <v-card-title class="float-left pa-0">
            <v-app-bar
              flat
              class="white pa-0"
              elevate-on-scroll
              dense
            >
              <v-toolbar-title
                data-cy="screen_log_title"
              >
                {{ $t('screen.log.title') }}
              </v-toolbar-title>
              <v-spacer />
            </v-app-bar>
          </v-card-title>
          <!-- <v-btn right absolute class="white--text" color="#1976D2">
                            <v-icon left>mdi-content-save</v-icon>
                            {{ $t('screen.log.save') }}
                        </v-btn> -->
        </div>
      </div>
      <loading
        :active.sync="requestLoading"
        :is-full-page="false"
      />
      <v-server-table
        class="pt-0"
        ref="table"
        name="scan/logTable"
        :columns="columns"
        :options="options"
      >
        <!-- <div
          slot="ref_id"
          slot-scope="props"
        >
          {{ $t('screen.batch.type.options')[props.row.type] }}
        </div> -->
        <div
          slot="type"
          slot-scope="props"
        >
          <p>{{ $t('screen.batch.type.options')[props.row.type] }}</p>
        </div>

        <!-- <div slot="user">
          <p>Tessasia</p>
        </div> -->

        <div
          slot="create_date"
          slot-scope="props"
        >
          <DateFormat
            :date="props.row.create_date"
            :showtime="true"
          />
        </div>

        <div
          slot="duration"
          slot-scope="props"
        >
          {{ moment.duration(moment(props.row.create_date).diff(moment(props.row.updated_date))).locale($i18n.locale == 'zh-Hant' ? 'zh-tw' : 'en').humanize() }}
          <!--<DateFormat :date="props.row.progress_last_touch" :showtime="true"/>
                    {{moment.duration(moment(props.row.create_date).diff(moment(props.row.updated_date))).locale($i18n.locale == 'zh-Hant' ? 'zh-tw' : 'en').humanize()}}.format("d [day] hh [hr] mm [min] ss [sec]", {usePlural: false})
                    {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("d [日] hh [小時] mm [分鐘] ")}}-->
          <!-- <span v-if="$i18n.locale == 'en'">
                        <span v-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) < 60000">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("m [min] ", {minValue: 1})}}
                        </span>
                        <span v-else-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) < 3.6e+6">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("m [min]", {maxValue: 60})}}
                        </span>
                        <span v-else-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) >= 3.6e+6">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("h [hr]", {maxValue: 24})}}
                        </span>
                        <span v-else-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) >= 8.64e+7">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("d [day]", {maxValue:1})}}
                        </span>
                   </span>
                   <span v-else>
                       <span v-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) < 60000">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("m [分鐘] ", {minValue: 1})}}
                        </span>
                        <span v-else-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) < 3.6e+6">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("m [分鐘]", {maxValue: 60})}}
                        </span>
                        <span v-else-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) >= 3.6e+6">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("h [小時]", {maxValue: 23})}}
                        </span>
                        <span v-else-if="moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))) >= 8.64e+7">
                            {{moment.duration(moment(props.row.updated_date).diff(moment(props.row.create_date))).format("d [日]", {maxValue:1})}}
                        </span>
                   </span> -->
        </div>

        <div
          slot="processedRecords"
          slot-scope="props"
        >
          {{ props.row.total_processed_person + props.row.total_processed_company /*props.row.total_company_ids + props.row.total_person_ids */ }}
        </div>

        <div
          slot="status"
          slot-scope="props"
        >
          <!--<v-icon left>{{ props.row.is_completed == 1 ? 'mdi-check-circle'
                        : ((props.row.is_paused == 1 && props.row.is_completed == 0 ) ? 'mdi-pause-circle'
                        :  'mdi-alert-circle') }}
                    </v-icon>-->
          <v-icon
            style="color: #36F27B"
            v-if="props.row.is_completed == 1 "
          >
            mdi-check-circle
          </v-icon>
          <v-icon
            style="color: #FFB129"
            v-else-if="props.row.is_paused == 1 && props.row.is_completed == 0 "
          >
            mdi-stop-circle
          </v-icon>
          <v-icon
            style="color: #F44336"
            v-else-if="props.row.is_failed == 1"
          >
            mdi-alert-circle
          </v-icon>
          <v-icon v-else />

          <!--<span v-if="props.row.is_completed == 1">{{$t('screen.log.table.status.option.completed')}}</span>
                   <span v-else-if="props.row.is_completed == 0 && props.row.is_paused == 1 ">{{$t('screen.log.table.status.option.paused')}}</span>
                   <span v-else-if="props.row.is_failed == 1">{{$t('screen.log.table.status.option.failed')}}</span>
                   <span v-else>N/A</span>-->

          {{ $t(`screen.log.table.status.option.${props.row.is_completed == 1 ? 'completed'
            : ((props.row.is_paused == 1 && props.row.is_completed == 0) ? 'stop'
              : (props.row.is_failed == 1) ? 'failed' : 'other') }`) }}
        </div>
        <div
          slot="actions"
          slot-scope="props"
        >
          <v-btn
            icon
            @click="batch_scan_detail_record=props.row; batch_scan_detail=true"
            :data-cy="`view_screen_detail_btn_${props.index}`"
          >
            <v-icon>mdi-magnify</v-icon>
          </v-btn>
        </div>
      </v-server-table>
      <v-dialog
        v-model="batch_scan_detail"
        max-width="1300px"
      >
        <v-card>
          <BatchDetail
            :record="batch_scan_detail_record"
            @closeDialog="closeDialog"
          />
        </v-card>
      </v-dialog>
    </v-container>
  </v-card>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex'
import DateFormat from '@/components/common/DateFormat'
import BatchDetail from '@/components/popups/PopupBatchScreenDetail'

export default {
  name: 'ScanLog',
  components: {
    DateFormat,
    BatchDetail
  },
  computed: {
    ...mapGetters('scan', {
      options: 'listing_options',
      batch_details: 'batch_details'
    }),
    ...mapState('scan', {
      requestLoading: state => state.listing.requestLoading,
      columns: state => state.listing.columns,
      dateColumns: state => state.listing.dateColumns
    })
  },
  data () {
    return {
      batch_scan_detail: false,
      batch_scan_detail_record: null
    }
  },
  methods: {
    ...mapActions('scan', ['get']),
    closeDialog (bool) {
      this.batch_scan_detail = bool
    }
  }
}
</script>

<style>
div#example1_paginate, div#example1_filter {
    text-align: right;
}
button.btn-sm.btn-block {
    width: 80px;
    float: left;
    margin: 0 5px;
}
</style>
